import React, { useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";

const initialFabricationBeds = [
  {
    id: 1,
    length: 20,
    width: 15,
    weightCapacity: 32,
    allocatedParts: [],
  },
  {
    id: 2,
    length: 30,
    width: 18,
    weightCapacity: 100,
    allocatedParts: [],
  },
];

const initialShipParts = [
  { sequence: 1, size: 10, duration: 30, weight: 20 },
  { sequence: 2, size: 15, duration: 50, weight: 50 },
  { sequence: 3, size: 5, duration: 40, weight: 10 },
  { sequence: 4, size: 10, duration: 20, weight: 85 },
  { sequence: 5, size: 12, duration: 10, weight: 53 },
  { sequence: 6, size: 20, duration: 60, weight: 62 },
  { sequence: 7, size: 10, duration: 20, weight: 33 },
];

function MainAreaPlacement() {
  const [newUnit, setNewUnit] = useState({
    size: "",
    duration: "",
    weight: "",
  });
  const [fabricationBeds, setFabricationBeds] = useState(
    initialFabricationBeds
  );
  const mainAreaWidth = 30;

  const [units, setUnits] = useState(initialShipParts);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUnit((prevNewUnit) => ({
      ...prevNewUnit,
      [name]: parseInt(value),
    }));
  };

  const addUnit = () => {
    if (newUnit.size && newUnit.duration && newUnit.weight) {
      const sequence = units.length + 1;
      setUnits((prevUnits) => [
        ...prevUnits,
        { sequence, ...newUnit, allocated: false },
      ]);
      setNewUnit({
        size: "",
        duration: "",
        weight: "",
      });
    }
  };

  const optimizeBeds = (units) => {
    const updatedBeds = [...fabricationBeds];
    units
      .filter((unit) => !unit.allocated)
      .forEach((part) => {
        updatedBeds.forEach((bed) => {
          if (
            !part.allocated &&
            part.size <= bed.length &&
            part.weight <= bed.weightCapacity
          ) {
            bed.allocatedParts.push(part);
            part.allocated = true;
          }
        });
      });
    setFabricationBeds(updatedBeds);
  };

  useEffect(() => {
    optimizeBeds(units);
  }, [units]);

  const optimizePlaces = (units) => {
    const placements = [];
    let currentX = 0;
    let currentY = 0;

    for (const unit of units) {
      const placement = {
        unit,
        startX: currentX,
        endX: currentX + unit.size,
        startY: currentY,
        endY: currentY + unit.duration,
      };

      if (placement.endX > mainAreaWidth) {
        currentX = 0;
        currentY = placements.reduce(
          (maxY, placedUnit) =>
            placedUnit.endY > maxY ? placedUnit.endY : maxY,
          0
        );

        placement.startX = currentX;
        placement.endX = currentX + unit.size;
        placement.startY = currentY;
        placement.endY = currentY + unit.duration;
      }

      currentX = placement.endX;
      placements.push(placement);
    }
    return placements;
  };

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <form style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="number"
            name="size"
            placeholder="Size"
            value={newUnit.size}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="duration"
            placeholder="Duration"
            value={newUnit.duration}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="weight"
            placeholder="Weight"
            value={newUnit.weight}
            onChange={handleInputChange}
          />
          <button type="button" onClick={addUnit} style={{ width: 100 }}>
            Add Unit
          </button>
        </form>
      </Box>

      <Box>
        <h1>-----------------------------</h1>
      </Box>

      <Grid
        container
        rowSpacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {fabricationBeds.map((bed) => (
            <div key={bed.id}>
              <h2>Bed {bed.id} / Max weight: {bed.weightCapacity}T</h2>
              <ul>
                {bed.allocatedParts.map((part) => (
                  <li key={part.sequence}>
                    Unit {part.sequence}: [
                    {bed.allocatedParts.indexOf(part) * 10}m -{" "}
                    {(bed.allocatedParts.indexOf(part) + 1) * 10}m] x [0 days -{" "}
                    {part.duration} days] - Size: {part.size} / Duration: {part.duration} / Weight: {part.weight}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {fabricationBeds.map((bed) => (
            <div key={bed.id}>
              <h2>Bed {bed.id}</h2>
              <GridLayout
                style={{
                  backgroundColor: "#D6DBDF",
                  width: mainAreaWidth * 25,
                  marginBottom: 10,
                  marginLeft: 10,
                }}
                className="layout"
                cols={50}
                rowHeight={10}
                width={mainAreaWidth * 40}
              >
                {optimizePlaces(bed.allocatedParts).map((placement) => (
                  <div
                    key={placement.unit.sequence}
                    data-grid={{
                      x: placement.startX,
                      y: placement.startY,
                      w: placement.unit.size,
                      h: placement.unit.duration / 5, // Adjust the duration here
                    }}
                    style={{ backgroundColor: "#00C9A7" }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                      fontSize={10}
                      fontWeight={600}
                    >
                      Unit {placement.unit.sequence}: [{placement.startX}m -{" "}
                      {placement.endX}m] x [{placement.startY} days -{" "}
                      {placement.endY} days]
                    </Typography>
                  </div>
                ))}
              </GridLayout>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default MainAreaPlacement;
